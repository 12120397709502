.option {
  inline-size: max-content;
}

.tag {
  font-size: 13px;
  font-weight: 600;
  padding: 2px;
  border-radius: 5px;
  width: 210px;
  text-align: center;
}

.center {
  text-align: center;
}

.actionWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.customDropdown > div {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.customDropdown > span {
  right: 14px;
}

.footerBtn {
  margin: 10px 0 0;
  text-align: right;
}

.excludedRow > td {
  background: transparent !important;
}

.suggestedActionCell {
  color: gray;
  font-style: italic;
  text-align: center;
}
