.formWrapper {
  padding: 0px;
  margin: 0px;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.selectWrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  /* align-items: center; */
  padding: 15px;
  border-radius: 3px;
  margin: 0px 40px 0px;
}

.criteriaLabel {
  font-size: 15px;
  font-weight: 900;
  margin-right: 10px;
}

.criteriaForm {
  padding: 0px;
  margin: 0px;
  width: 90%;
}

.title {
  text-align: left;
}

.noMargin {
  margin: 0px;
}

.modalContainer {
  position: relative;
}

.formLoader {
  pointer-events: none;
  opacity: 0.4;
}

.loadingSpinner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
