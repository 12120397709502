.input,
.inputExecuteActions {
  margin: 20px 0 10px;
}

.textEditor {
  margin-bottom: 30px;
}

.textEditorExecuteActions {
  margin-bottom: 45px;
}
