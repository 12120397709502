.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.badge {
  display: flex;
  background-color: #00a3ed;
  color: white;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  padding: 4px 10px;
  height: 30px;
}
