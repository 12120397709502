.container {
  margin: 0 2em 48px;
}

.section {
  background-color: #f4f4f4;
  padding: 16px;
  text-transform: uppercase;
}

.content {
  margin: 2em 0;
}

.group {
  margin-bottom: 24px;
}

.select {
  width: 45%;
}
