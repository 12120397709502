.outlineBtn {
  border: none;
}

.primaryBtn {
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.dragBtn {
  cursor: 'grab';
  color: '#999';
}
