.container {
  margin: 0 5em 48px;
}

.section {
  background-color: #f4f4f4;
  padding: 16px;
  text-transform: uppercase;
}

.content {
  margin: 2em 0;
}

.group {
  margin-bottom: 24px;
}

.formItem {
  line-height: 1;
  margin-bottom: 0.5em;
}
