.container {
  padding: 0 0 15px 18px;
}

.container .wrap {
  display: flex;
  gap: 10px;
}

.container .title {
  font-size: 20px;
  padding: 0px;
  margin: 0px;
  text-align: center;
}

.container .containerCenter {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.container .containerRight {
  text-align: right;
}
