.table {
  padding: 15px 0 15px 18px;
}

.ant-skeleton-title {
  margin-bottom: 20px;
}

.ant-table-row .ant-table-cell.cell-green {
  background-color: #aedd93 !important;
}

.ant-table-row .ant-table-cell.cell-light-green {
  background-color: #dfedd6 !important;
}

.ant-table-row .ant-table-cell.cell-red {
  background-color: #fb5c55 !important;
}

.ant-table-row .ant-table-cell.cell-light-red {
  background-color: #fdb4b0 !important;
}
