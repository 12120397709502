.container {
  min-height: 90vh;
  background-color: #fff;
}

.layout {
  background-color: #fff;
}

.layout .content {
  margin: 10px 40px;
  min-height: 280px;
}
