.collapse {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}

.collapse .panel {
  overflow: auto;
}

.item {
  font-weight: 600;
  white-space: nowrap;
  margin: 5px 0;
  padding: 0px;
}
