.p-4 {
  padding: 1rem;
}
.font-alt {
  font-family: 'Open Sans', sans-serif !important;
}
.font-semibold {
  font-weight: 500;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.ws-nowrap {
  white-space: nowrap;
}

/**START : Common color classes**/
.primary-color {
  color: #222630;
}

.bg-color-primary {
  background-color: #222630;
}

.bg-color-purple {
  background-color: #725bb5;
}

.text-color-purple {
  color: #725bb5;
}

.border-left-purple {
  border-left: 8px solid #725bb5;
}

.bg-color-info {
  background-color: #17a2b8 !important;
}

.bg-color-alert {
  background-color: #ff7a7a !important;
}

.bg-color-default {
  background-color: #ccc;
}

.bg-color-dark-grey {
  background-color: #ededed;
}

.text-color-dark-grey {
  background-color: #ededed;
}

.bg-color-light-grey {
  background-color: #f1f0f8;
}
.text-color-light-grey {
  background-color: #f1f0f8;
}

.bg-color-white {
  background-color: #fff;
}
/**END : Common color classes**/

/**COMMON TABLE, TABLE-WRAPPER CSS**/
.custom-s-bar {
  overflow: auto;
}
.box-shadow-grey {
  box-shadow: 4px 0px 8px 8px #e0e0e0;
}

.box-shadow-grey-top-0 {
  box-shadow: 4px 6px 6px 0px #e0e0e0;
}

.g-table-ui table {
  border-collapse: separate;
  border-spacing: 0;
}

.g-table-ui table th,
.g-table-ui table td {
  border-top: 1px solid #222630;
  border-bottom: 1px solid #222630;
  padding: 8px 12px;
  font-size: 14px;
}

.g-table-ui table th,
.g-table-ui table td {
  min-width: 100px;
  width: auto;
}

.g-table-ui table th.min-w-150p,
.g-table-ui table td.min-w-150p {
  min-width: 150px;
}

.g-table-ui table th.min-w-200p,
.g-table-ui table td.min-w-200p {
  min-width: 200px;
}

.g-table-ui table thead th,
.g-table-ui table thead td {
  white-space: nowrap;
  font-weight: 500;
}

.g-table-ui table tbody td {
  padding: 4px 12px;
  font-size: 13px;
  border-bottom-color: #ededed;
  border-top: none;
}

.g-table-ui table .title-bg-color {
  background-color: #f7f7f7;
}

.g-table-ui table tr.title-bg-color td {
  background-color: #f7f7f7;
}

.g-table-ui table tr.title-bg-color th {
  background-color: #f7f7f7;
}

.g-table-ui table .sub-total-tier-1 td {
  background-color: #f9f9fa;
}

.g-table-ui table .sub-total-tier-2 td {
  background-color: #f9f9fa;
}

.g-table-ui table .sub-total-tier-3 td {
  background-color: #f9f9fa;
}

.g-table-ui table .amount:hover {
  color: #725bb5;
  text-decoration: underline;
  cursor: pointer;
}

.g-table-ui table .amount:empty:hover {
  cursor: auto;
}

.g-table-ui table tbody tr.border-bt td {
  border-bottom-color: #222630;
  border-top: 1px solid #222630;
}

.g-table-ui table .border-none {
  border: none;
}

.g-table-ui table .border-b-none {
  border-bottom: none;
}

.g-table-ui table .border-bt-none {
  border-top: none;
  border-bottom: none;
}

.g-table-ui table .border-r {
  border-right: 1px solid #222630;
}

.g-table-ui table .border-r-grey {
  border-right: 1px solid #ededed;
}

.g-table-ui table .border-l {
  border-left: 1px solid #222630;
}

.g-table-ui table .border-b {
  border-bottom: 1px solid #222630;
}

.g-table-ui table .border-t {
  border-top: 1px solid #222630;
}

.g-table-ui table .border-lr {
  border-left: 1px solid #222630;
  border-right: 1px solid #222630;
}

.g-table-ui table .header-column {
  vertical-align: top;
  border-right: 1px solid #222630;
  text-align: center;
  font-weight: 500;
  background-color: #f7f7f7;
  border-bottom: none;
}
.g-table-ui table .vendor-column {
  border-right: 1px solid #222630;
  font-weight: 500;
  background-color: white;
  word-break: break-word;
}

/**COMMON TABLE, TABLE-WRAPPER CSS**/

#review-center-page #view-title {
  font-size: 17px;
}

/**REVIEW CENTER TABLE UI**/
#review-center-page .table-wrapper {
  height: calc(100vh - 143px);
  overflow-y: auto;
}

#review-center-page .new-rc-table tr td:last-child {
  border-right: 1px solid #222630;
}

#review-center-page .table-wrapper table tbody td:not(.sticky-col) {
  text-align: right;
  border-right: 1px solid #ededed;
}

#review-center-page .table-wrapper table .sticky-row-2 td:not(.sticky-col) {
  border-right: 1px solid #ededed;
}

#review-center-page .table-wrapper table .sticky-row-2 td.border-r,
#review-center-page .table-wrapper table .sticky-row-2 td:last-child,
#review-center-page .table-wrapper table tbody td.border-r,
#review-center-page .table-wrapper table tbody td:last-child {
  border-right: 1px solid #222630;
}

#popup-table-wrapper td,
#popup-table-wrapper th {
  border-right: 1px solid #ededed;
}

#popup-table-wrapper td.border-r,
#popup-table-wrapper th.border-r {
  border-right: 1px solid #222630;
}

#review-center-page table tbody .sticky-col {
  word-break: break-word;
}
/**TABLE UI**/

/**END : NEW DASHBOARD CSS**/

/**LOADER**/
#loader-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  z-index: 5;
}
#loader-wrapper img {
  display: inline-block;
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
/**LOADER**/

/**POPUP**/
#popup-table-wrapper {
  max-height: 100%;
  overflow-y: auto;
  padding: 0px 8px;
}
#popup-close-button:hover {
  background-color: #222630;
}
/**POPUP**/

/**FREEZE ROWS AND COLUMNS - COMMON CSS**/
table tr.sticky-row th,
table tr.sticky-row td {
  -webkit-position: sticky;
  position: sticky;
  z-index: 1;
}
table tr.sticky-row-1 th {
  top: 0;
}

table tr.sticky-row-2 td {
  top: 38px;
}

table tr.sticky-row .sticky-col {
  z-index: 3;
}

table .sticky-col {
  -webkit-position: sticky;
  position: sticky;
  z-index: 2;
  min-width: 125px !important;
  max-width: 125px !important;
  width: 125px !important;
}
table .sticky-col-1 {
  left: 0;
}

table .sticky-col-2 {
  left: 125px;
}
table .sticky-col-3 {
  left: 250px;
}
table .sticky-col-4 {
  left: 375px;
}
/**FREEZE ROWS AND COLUMNS**/

.upload-file-modal-close-button {
  display: flex;
  justify-content: flex-end;
}

.rc {
  height: 900px;
}

.rc .btn-tab {
  height: 39px;
  display: inline-block;
  border-radius: 0;
  margin-right: 5px;
}

.rc .btn-inactive {
  background-color: lightgray;
  color: black;
}

.sweet-alert {
  box-sizing: border-box;
  max-height: 100% !important;
  overflow-y: auto !important;
  padding: 0 17px 17px !important;
  width: 512px;
}

.sweet-alert:before {
  content: '';
  display: block;
  height: 17px;
  width: 0;
}

.swal-size-large {
  width: 700px !important;
}

.w-full {
  width: 100%;
}
