.sider {
  background-color: #f6f5f5;
  margin-top: -18px;
  margin-left: -18px;
  margin-bottom: -76px;
  padding-top: 18px;
  padding-left: 18px;
}

.sider .wrapper {
  position: relative;
}

.sider .wrapper .btn {
  font-size: 23px;
  color: #5831da;
  position: absolute;
  top: 10px;
  z-index: 1;
  transition: right 0.2s ease-in-out;
  background-color: white;
  border-radius: 12px;
}

.hovered {
  color: #fff;
  font-size: 23px;
  position: absolute;
  top: 10px;
  z-index: 1;
  transition: right 0.2s ease-in-out;
  background-color: #5831da;
  border: 0.75px solid #5831da;
  border-radius: 12px;
}

.customList {
  padding: 10px;
}

.customList > div:first-child {
  border-bottom: none;
}

.hidden {
  opacity: 0;
  max-height: 0;
  transition: max-height 0.15s ease-out, opacity 0.15s ease-out;
}

.visible {
  opacity: 1;
  max-height: 1000px;
  transition: max-height 0.2s ease-in, opacity 0.15s ease-in;
}

.customList .title {
  font-size: 14px;
  font-weight: 600;
  padding: 0px;
  margin: 0px;
  border: none;
}

.customList .item {
  padding: 4px 0px;
  margin: 0px;
  border: none;
  margin-left: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

.divider {
  padding: 0px;
  margin: 0px;
}

.selected {
  color: #fff;
  background-color: #e8e8e8;
  width: 170px;
  border-radius: 5px;
}

.selectedText {
  color: #5831da;
  position: relative;
}

.selectedText:after {
  content: ' ';
  position: absolute;
  border-left: 7px #5831da solid;
  top: 20%;
  left: -20px;
  height: 60%;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 2px;
}
