.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.input {
  width: 500px;
}

.tooltip {
  margin-left: 5px;
}
