.outerContainer {
  border: 1px solid #f0f0f0;
  border-radius: 1em;
  margin-bottom: 2em;
  padding: 2em 0;
}

.innerContainer {
  width: 75%;
  margin: 0 auto;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}

.button {
  width: 80px;
}

.disabledInput {
  padding-right: 55px;
}

.validityPeriod {
  display: block;
  margin-top: -24px;
}

.expired {
  color: red;
}
