.outerContainer {
  background-color: black;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.innerContainer {
  height: 100%;
  margin: -18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1000;
}

.contentContainer {
  background-color: white;
  border-radius: 10px;
  max-width: 400px;
  padding: 20px;
  text-align: center;
}
