.tbl-review-center .ant-table.ant-table-bordered > .ant-table-container {
  border-left: 1px solid white;
}

.tbl-review-center .ant-table.ant-table-bordered > .ant-table-container table {
  border-top: 1px solid white !important;
}

.tbl-review-center tr .cell-header-dimensions.cell-index-0 {
  border-left: 1px solid #222630 !important;
}

.tbl-review-center .ant-table-container table thead tr:first-child th {
  border-top-left-radius: 0 !important;
}

.tbl-review-center thead tr:first-child th:not(:first-child) {
  border-top: 1px solid #222630;
  text-align: center !important;
}

.tbl-review-center thead tr th {
  background: #f7f7f7 !important;
  border-bottom: 1px solid #222630 !important;
  font-size: 14px;
  font-weight: bolder;
  padding: 8px 12px !important;
}

.tbl-review-center tbody tr.ant-table-row td {
  font-size: 13px;
  padding: 4px 12px !important;
  vertical-align: top;
}

.tbl-review-center tbody tr.ant-table-row td.cell-totals {
  background: #f7f7f7 !important;
  border-bottom: 1px solid #222630 !important;
  border-top: 1px solid #222630;
}

.tbl-review-center
  tbody
  tr.ant-table-row
  td.cell-header-dimensions.cell-totals {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tbl-review-center tbody tr.ant-table-row td.cell-bold {
  border-right: 1px solid #222630 !important;
  font-weight: bolder;
}

.tbl-review-center tbody tr.ant-table-row td.cell-header-dimensions {
  border-right: 1px solid #222630 !important;
}

.tbl-review-center
  td.cell-header-dimensions:not(.ant-table-cell-fix-left-last) {
  background: #f7f7f7 !important;
  text-align: center;
}

.tbl-review-center tbody tr th.cell-header-dimensions,
.tbl-review-center thead tr th.cell-header,
.tbl-review-center tr .cell-last {
  border-right: 1px solid #222630 !important;
}

.tbl-review-center thead tr th.cell-header-no-bg {
  background: white !important;
  border: 1px solid white;
}

.tbl-review-center .ant-table-container thead tr:first-child th:last-child {
  border-top-right-radius: 0 !important;
}

.tbl-review-center .ant-table-ping-left tbody tr .cell-header-dimensions:after {
  content: '';
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  pointer-events: none;
}

.tbl-review-center tbody tr.ant-table-row td {
  background: white !important;
}
