.header {
  display: flex;
  justify-content: space-between;
}

.btn {
  margin-left: 5px;
  margin-right: 5px;
}

.form_container {
  padding: 40px 100px 40px 100px;
}

.form_dimension_fields {
  display: flex;
  /* width: 100%; */
  /* justify-content: space-between; */
  align-items: flex-start;
  align-self: stretch;
}

.form_dimension_fields > * {
  width: calc(100% / 3);
}

.form_dimension_fields_item {
  width: 100%;
}

.query_params {
  display: flex;
  justify-content: space-between;
}
