.formListWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.addFilterBtnWrap {
  display: flex;
  justify-content: space-between;
}

.filterBtn {
  background-color: #6652a8;
  border: none;
  color: #fff;
  box-shadow: none;
}

.filterBtn:hover,
.filterBtn:active,
.filterBtn:focus {
  background-color: #383a3b;
  border: none;
  color: #fff !important;
  box-shadow: none;
}

.saveBtn {
  background-color: #6652a8;
  border: none;
  color: #fff;

  &:hover {
    background-color: #d3cdeb;
    border: none;
  }
}

.loading > div > div {
  opacity: 0.5;
  pointer-events: none;
}
