.modal {
  top: 210px;
}

.modal .errorMessage {
  width: 95%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  margin-bottom: 20px;
}

.header {
  display: flex;
  justify-content: center;
}

.header img {
  width: 55%;
  max-height: 53px;
}

.formContainer {
  padding-top: 20px;
}

.formContainer .input {
  border-radius: 27px;
  padding: 2px 16px;
  height: 43px;
}

.button {
  width: 100%;
  border-radius: 4rem !important;
  height: 46px;
  font-size: 18px;
}
