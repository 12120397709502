.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.button {
  width: 150px;
  height: 40px;
}
