.container {
  background-color: #baaed0;
  padding: 10px;
  margin-left: 18px;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  position: relative;
}

.container .button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  gap: 5px;
  border: 2px solid #6652a8;
  padding-left: 10px;
}

.container .button:hover {
  background-color: #383a3b;
  color: #fff !important;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  gap: 10px;
  font-size: 12px;
  padding: 5px 0px 5px 10px;
  cursor: auto;
}

.item > span {
  cursor: default;
}

.periodCovered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 900;
}
